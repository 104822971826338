
import {SectionHeading } from '../../components/page.component';
import { useSelector } from 'react-redux';
import { ModalImageSection } from '../../components/modal-image-section.component';
import { selectPhotoPortfolioItems } from '../../store/photo-portfolio/photo-portfolio-selector';


const PhotoPortfolioSection = (props)=>{
    const {lang} = props;
    const items = useSelector(selectPhotoPortfolioItems);
    return(
        <>
            <SectionHeading title={". / Photo"} />
            <ModalImageSection data ={items} maxCols={4} />
        </>
    );
}

export default PhotoPortfolioSection;