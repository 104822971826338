
import {SectionHeading } from '../../components/page.component';
import { useSelector } from 'react-redux';
import { ModalImageSection } from '../../components/modal-image-section.component';
import { selectAnimationPortfolioItems } from '../../store/animation-portfolio/animation-portfolio-selector';

const AnimationPortfolioSection = (props)=>{
    const {lang} = props;
    const items = useSelector(selectAnimationPortfolioItems);
    return(
        <>
           <SectionHeading title={". / Animation"} />
            <ModalImageSection data ={items} maxCols={4} />
        </>
    );
}

export default AnimationPortfolioSection;