
import {SectionHeading } from '../../components/page.component';
import { useSelector } from 'react-redux';
import { selectVideoPortfolioItems } from '../../store/video-portfolio/video-portfolio-selector';
import { ModalImageSection } from '../../components/modal-image-section.component';


const VideoPortfolioSection = (props)=>{
    const {lang} = props;
    const items = useSelector(selectVideoPortfolioItems);
    return(
        <>
            <SectionHeading title={". / Video"} />
            <ModalImageSection data ={items} maxCols={4} />
        </>
    );
}

export default VideoPortfolioSection;