import { combineReducers } from "@reduxjs/toolkit";
import { webPortfolioReducer } from "./web-portfolio/web-portfolio-reducer";
import { videoPortfolioReducer } from "./video-portfolio/video-portfolio-reducer";
import { photoPortfolioReducer } from "./photo-portfolio/photo-portfolio-reducer";
import { animationPortfolioReducer } from "./animation-portfolio/animation-portfolio-reducer";
import { expPortfolioReducer } from "./exp-portfolio/exp-portfolio-reducer";

export const rootReducer = combineReducers({
    webPortfolio: webPortfolioReducer,
    videoPortfolio: videoPortfolioReducer,
    photoPortfolio: photoPortfolioReducer,
    animationPortfolio: animationPortfolioReducer,
    expPortfolio: expPortfolioReducer
});
