/*

webPortfolioReducer

*/
import { createSlice } from "@reduxjs/toolkit";
import imgCard1 from "../../assets/images/web/card1.jpg";
import imgCard2 from "../../assets/images/web/card2.jpg";
import imgCard3 from "../../assets/images/web/card3.jpg";
import imgCard4 from "../../assets/images/web/card4.jpg";

const INITIAL_STATE = {
    items: [
        {
          title: "hi-ming.io",
          href: "https://hi-ming.io",
          src: imgCard1,
          text: `My photography website.\n - WordPress block theme.\n - Custom Image gallery / YouTube / dropdown filter blocks.\n - Multilingual pages done using custom post type.\n - Customised 3rd part shopping cart. `,
          japanese: `私の写真HP。\n - WordPress ブロックデーマ\n - カスタムギャラリーブロック\n -カスタムYoutubeブロック\n - カスタムフィルターブロック\n - 多言語対応CPT\n - ショッピングカートスタイル変更`
        },
        {
          title: "Helium Codes",
          href: "https://helium.codes",
          src: imgCard2,
          text: `My digital art website. \n - WordPress block theme.\n - Custom YouTube block\n - Custom latest posts block`,
          japanese: `私のデジタルアートHP。\n -WordPress ブロックデーマ\n -  カスタムYoutubeブロック\n -  カスタムクエリーポストブロック`
        },
        {
          title: "Callistemon Pyschology",
          href: "https://callistemonpsychology.com.au/",
          src: imgCard3,
          text: `Migrant the website from Wix to WordPress (not my design, coding only) for a client.`,
          japanese: `お客様のホームページWixからWordPressへ移行する。`
        },
        {
          title: "Photographer's website",
          href: "https://www.figma.com/proto/9jZYY3pQl9442nyMgYTnKE/demo?type=design&node-id=46-404&t=Wju4HULvwVp3hVCX-1&scaling=scale-down&page-id=30%3A226&mode=design",
          src: imgCard4,
          text: `Recent Design proposal to a client, logo and images replaced with stock images.`,
          japanese: `デザインの提案、プライバシー保護ため、ダミーテキストとフリー素材に入れ替わりました。`
        }
      ]
}

const webPortfolioSlice = createSlice({
    name: 'web',
    initialState: INITIAL_STATE,
    reducers: {
        setWebPortolioItems(state, action){
            state.items = action.payload;
        }
    }
});

export const {setWebPortolioItems} = webPortfolioSlice.actions;
export const webPortfolioReducer = webPortfolioSlice.reducer;