import { createSlice } from "@reduxjs/toolkit";
import pFukuoka from "../../assets/images/photo/fukuoka.jpg";
import pKobe from "../../assets/images/photo/kobe.jpg";
import pOtaru from "../../assets/images/photo/otaru.jpg";
import pJapan1 from "../../assets/images/photo/japan1.jpg";
import pTokyo1 from "../../assets/images/photo/tokyo1.jpg";
import pTokyo3 from "../../assets/images/photo/tokyo3.jpg";
import pTokyoPano from "../../assets/images/photo/tokyo-pano1.jpg";
import pSapporo from "../../assets/images/photo/sapporo.jpg";

const INITIAL_STATE = {
    items: [
      {
        href: "https://www.youtube.com/embed//tI0KxqBnxzQ",
        src: pOtaru
      },
  
      {
        href: "https://www.youtube.com/embed/7-k3GTGlNG4",
        src: pTokyo1
      },
  
      {
        href: "https://www.youtube.com/embed/x1J5e8nBvZE",
        src: pKobe
      },
  
      {
        href: "https://www.youtube.com/embed/qB5JMrZdg_M",
        src: pTokyo3
      },
  
      {
        href: "https://www.youtube.com/embed/qBXwo4W_2P8",
        src: pJapan1
      },
  
      {
        href: "https://www.youtube.com/embed/4SJC__q_Zc4",
        src: pFukuoka
      }
      ,
      {
        href: "https://www.youtube.com/embed/IRL2gzXApjQ",
        src: pTokyoPano
      },
      {
        href: "https://www.youtube.com/embed/CTapRJbVYMM",
        src: pSapporo
      }
    ]
}

const photoPortfolioSlice = createSlice({
    name: 'photoPortfolio',
    initialState: INITIAL_STATE,
    reducers: {
        setPhotoPortfolio(state, action){
            state.items = action.payload;
        }
    }
});

export const setPhotoPortfolio = photoPortfolioSlice.actions;
export const photoPortfolioReducer = photoPortfolioSlice.reducer;