import '../../scss/App.scss';
import { PageHeader, FooterSection } from '../../components/page.component';
import WebPortfolioSection from '../../components/portfolio/web-portfolio.component';
import VideoPortfolioSection from '../../components/portfolio/video-portfolio.component';
import PhotoPortfolioSection from '../../components/portfolio/photo-portfolio.component';
import AnimationPortfolioSection from '../../components/portfolio/animation-portfolio.component';
import ExpPortfolioSection from '../../components/portfolio/exp-portfolio.component';

function Home() {
  return (
    <div className="App">
        {/* Page Header */}
        <PageHeader /> 
        {/* Web portfolio */}
        <WebPortfolioSection />
        {/* Video work, 4 cols layout */}
        <VideoPortfolioSection />
        {/* Youtube photo work, 4 cols layout */}
        <PhotoPortfolioSection />
        {/* Animation, 4 cols layout */}
        <AnimationPortfolioSection />
        {/* Experimental section */}
        <ExpPortfolioSection />
      <FooterSection text={''} />
    </div>
  )
}
export default Home;
