import { useState} from 'react';
import Modal from 'react-bootstrap/Modal';
import { ImageDataProps } from './modal-image-block.component.type';
import { ModalImageBock } from './modal-image-block.component';

type ModalImageSectionProps = {
  data: ImageDataProps[],
  maxCols: number
}

type ModalState = {
  show: boolean,
  url: string
}

const ModalImageSection = (props:ModalImageSectionProps)=>{
    const {data, maxCols} = props; 
    const [showModal, setShowModal] = useState<ModalState>( {show: false, url: ''});
  
    function handleModal(url:string) {
      setShowModal({show: true, url} );
    }
  
    return(
      <>
        <ModalImageBock data={data} maxCols={maxCols} setModal = {handleModal} />
        <Modal 
          fullscreen = {"sm-down"} 
          show={showModal.show} 
          centered={true}  
          onHide={() => setShowModal({show: false, url: ''})}>
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body>
          <div className="ratio ratio-16x9">     
            <iframe
              className="embed-responsive-item" 
              src={showModal.url} title="YouTube video player" 
              allow={"encrypted-media; web-share"} 
              width="560"
              height="315"
              allowFullScreen loading="lazy"></iframe>
          </div>
        </Modal.Body>
      </Modal>
      </>
    )
  }


  export {ModalImageSection}