import '../../scss/App.scss';
import { PageHeader, FooterSection } from '../../components/page.component';
import WebPortfolioSection from '../../components/portfolio/web-portfolio.component';
import VideoPortfolioSection from '../../components/portfolio/video-portfolio.component';
import PhotoPortfolioSection from '../../components/portfolio/photo-portfolio.component';
import AnimationPortfolioSection from '../../components/portfolio/animation-portfolio.component';

function Japanese() {
  return (
    <div className="App">
        {/* Page Header */}
        <PageHeader /> 
        {/* Web portfolio */}
        <WebPortfolioSection lang='jp' />
        {/* Video work, 4 cols layout */}
        <VideoPortfolioSection lang='jp' />
        {/* Youtube photo work, 4 cols layout */}
        <PhotoPortfolioSection lang='jp' />
        {/* Animation, 4 cols layout */}
        <AnimationPortfolioSection lang='jp' />
      <FooterSection text={''} />
    </div>
  )
}
export default Japanese;
