import { Routes, Route } from 'react-router-dom';
import Home from './route/home/home.component';
import Japanese from './route/japanese/japanese.component';
import NotFound from './route/404/404.component';

function App() {
  return (
    <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/jp'  element={<Japanese />}  />
        <Route path='*' element={<NotFound />}/>
    </Routes>
  )
}
export default App;
