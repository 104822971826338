
import { CardSection} from '../../components/page.component';
import {SectionHeading } from '../../components/page.component';
import { useSelector } from 'react-redux';
import { selectExpPortfolioItems } from '../../store/exp-portfolio/exp-portfolio-selector';

const ExpPortfolioSection = (props)=>{
    const {lang} = props;
    const items = useSelector(selectExpPortfolioItems);
    let displayItems = items;

    if (lang === 'jp'){
        displayItems = items.map( (item)=>{
            const langItem = {...item};
            langItem.text = langItem.japanese;
            return langItem;
        });
    }
    
    return(
        <>
            <SectionHeading title={". / Experiment"} />
            <CardSection portfolioCardData={displayItems} />
        </>
    );
}

export default ExpPortfolioSection;