import { Container, Row, Col } from "react-bootstrap";
import { PortfolioCardProps } from "./portfolio-card.component.type";
import { PortfolioCard } from "./portfolio-card.component";
import { SectionHeadingProps, FooterProps} from "./page.component.type";
import { FC } from "react";

const PageHeader = ()=>{
    return (
      <Container as='header' fluid className='d-flex flex-column justify-content-center align-items-start page-header'>
        <Container><Row><Col><h1>Ming Chung Chung / <br /> Portfolio</h1></Col></Row></Container>
      </Container>
    )
  }

const SectionHeading = (props:SectionHeadingProps)=>{
    const {title} = props;
    return (
      <Container><Row><Col><h2>{title}</h2></Col></Row></Container>
    )
}

const CardSection:FC<{portfolioCardData: PortfolioCardProps[], className?: string}> = (props)=>{
  const {portfolioCardData, className} = props;
    return (
        <Container className={"mb-5 " + className}> 
          <Row className='g-5'>
            {portfolioCardData.map( (card)=>{
                return(
                <Col className='px-3' key={card.href} xs={12} sm={6} md={4} lg={3} xxl= {3} >
                    <PortfolioCard title={card.title} text={card.text}  href={card.href} src={card.src} />
                </Col>
                );
            })
            }
        </Row>
    </Container>
    )
}


const FooterSection = (props:FooterProps)=>{
  const {text} = props;
  const displaytext = (text)? text : "Copyright 2024 Ming Chun Chung. \n\r Build with React + Redux + Typescript + Bootstrap.";
  return (
    <Container fluid={true} as={"footer"}  className='d-flex flex-column'>
      <p className='footer-text'>{displaytext}</p>
    </Container>
  )
}


export {PageHeader, CardSection, SectionHeading, FooterSection}