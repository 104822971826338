
import { CardSection} from '../../components/page.component';
import {SectionHeading } from '../../components/page.component';
import { useSelector } from 'react-redux';
import { selectWebPortfolioItems } from '../../store/web-portfolio/web-portfolio-selector';

const WebPortfolioSection = (props)=>{
    const {lang} = props;
    const items = useSelector(selectWebPortfolioItems);
    let displayItems = items;

    if (lang === 'jp'){
        displayItems = items.map( (item)=>{
            const langItem = {...item};
            langItem.text = langItem.japanese;
            return langItem;
        });
    }
    
    return(
        <>
            <SectionHeading title={". / Web"} />
            <CardSection portfolioCardData={displayItems} />
        </>
    );
}

export default WebPortfolioSection;