import { Container, Row, Col, Image } from 'react-bootstrap';
import {ImageSizesProps, modalImageBlockProps } from './modal-image-block.component.type';


const ModalImageBock = (props: modalImageBlockProps)=>{
    const {data, maxCols, setModal} = props;
    let sizes:ImageSizesProps = {};
  
    // set grid column format
    switch (maxCols){
      case 4:
        sizes = {xs: 6, sm: 6, md: 4, lg: 3, xxl: 3};
        break;
      default:
        sizes = {xs: 6, sm: 6, md: 6, lg: 4, xxl: 4};
    }
  
    return (
      <Container className='mb-6'>
        <Row className='gx-2'>
          {data && data.length > 0 &&(         
            data.map ((item)=> {
              return (
                <Col key={item.src} className='p-1 image-block-item' xs={sizes.xs} sm={sizes.sm} md={sizes.md} lg={sizes.lg} xxl= {sizes.xxl} onClick={()=>{setModal(item.href) }}  >
                      <Image src={item.src} className='d-inlink-block w-100' />
                </Col> 
              )
            })
          )}
        </Row>
      </Container>
    )
  }

  export {ModalImageBock}