import { createSlice } from "@reduxjs/toolkit";

import a1 from "../../assets/images/animation/1.jpg";
import a2 from "../../assets/images/animation/2.jpg";
import a3 from "../../assets/images/animation/3.jpg";
import a4 from "../../assets/images/animation/4.jpg";
import a5 from "../../assets/images/animation/5.jpg";
import a6 from "../../assets/images/animation/6.jpg";
import a7 from "../../assets/images/animation/7.jpg";
import a8 from "../../assets/images/animation/8.jpg";

const INITIAL_STATE = {
    items: [
      {
        href: "https://www.youtube.com/embed//LSArQVzo4oM",
        src: a1
      },
  
      {
        href: "https://www.youtube.com/embed/gRWSySvQ_r4",
        src: a2
      },
  
      {
        href: "https://www.youtube.com/embed/AZA7PHvcbdI",
        src: a3
      },
  
      {
        href: "https://www.youtube.com/embed/RFpcY0KThs8",
        src: a4
      },
  
      {
        href: "https://www.youtube.com/embed/I8J5xo2NKbk",
        src: a5
      },
  
      {
        href: "https://www.youtube.com/embed/4hALk96Qrn4",
        src: a6
      },
      
      {
        href: "https://www.youtube.com/embed/hIB31Hl1vaY",
        src: a7
      },
  
      {
        href: "https://www.youtube.com/embed/C800rGcDEpM",
        src: a8
      }
    ]
}

const animationPortfolioSlice = createSlice({
    name: 'animationPortfolio',
    initialState: INITIAL_STATE,
    reducers: {
        setAnimationPortfolio(state, action){
            state.items = action.payload;
        }
    }
});

export const setAnimationPortfolio = animationPortfolioSlice.actions;
export const animationPortfolioReducer = animationPortfolioSlice.reducer;