import { createSlice } from "@reduxjs/toolkit";
import v1 from "../../assets/images/video/1.jpg";
import v2 from "../../assets/images/video/2.jpg";
import v3 from "../../assets/images/video/3.jpg";
import v4 from "../../assets/images/video/4.jpg";
import v5 from "../../assets/images/video/5.jpg";
import v6 from "../../assets/images/video/6.jpg";
import vDrift1 from "../../assets/images/video/drift1.jpg";
import vDrift2 from "../../assets/images/video/drift2.jpg";

const INITIAL_STATE = {
    items: [
        {
          href: "https://www.youtube.com/embed/2OTvbHS6aak",
          src: v1
        },
    
        {
          href: "https://www.youtube.com/embed/7Yg8IiphpKc",
          src: v2
        },
    
        {
          href: "https://www.youtube.com/embed/fzlHk6iUCOM",
          src: v4
        },
    
        {
          href: "https://www.youtube.com/embed/NXESXDa8xVs",
          src: v5
        },
    
        {
          href: "https://www.youtube.com/embed/0Yj6wKver2A",
          src: v3
        },
    
        {
          href: "https://www.youtube.com/embed/WPpK_K2onto",
          src: v6
        },
        
        {
          href: "https://www.youtube.com/embed/dHxPN5661OE",
          src: vDrift1
        },
    
        {
          href: "https://www.youtube.com/embed/_hl8XtADEeY",
          src: vDrift2
        }
      ]
}

const videoPortfolioSlice = createSlice({
    name: 'videoPortfolio',
    initialState: INITIAL_STATE,
    reducers: {
        setVideoPortfolio(state, action){
            state.items = action.payload;
        }
    }
});

export const setVideoPortfolio = videoPortfolioSlice.actions;
export const videoPortfolioReducer = videoPortfolioSlice.reducer;