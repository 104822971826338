import { Card, CardBody, CardLink, CardFooter} from "react-bootstrap";
import { ArrowRight } from "react-bootstrap-icons";
import { PortfolioCardProps } from "./portfolio-card.component.type";

const PortfolioCard = (props:PortfolioCardProps)=>{
    const {title, text, src, href} = props;

    return (
        <Card className="border-0 px-0 py-0 h-100">
            <CardBody className="px-0">
                <CardLink href={href} target="_blank">
                <Card.Img variant="top" className="d-inline-block" src={src} />
                </CardLink>
                <Card.Title ><p className="d-inline-block pb-2 card-title-text">{title}</p></Card.Title>
                <Card.Text>{text}</Card.Text>
            </CardBody>
            <CardFooter className="text-right">
                <a className="d-flex justify-content-end" href={href} target="_blank" rel="noopener noreferrer"><ArrowRight color="#7d7d7d" size={22} /></a>  
            </CardFooter>
        </Card>
    );
}

export {PortfolioCard};