import { Link } from "react-router-dom";

export default function NotFound() {
    return (
        <div className="error error404">
            <h1>Oops! You seem to be lost!</h1>
            <br />
            <h3><Link to='/'>Home</Link></h3>
        </div>
    )
}