/*

expPortfolioReducer

*/
import { createSlice } from "@reduxjs/toolkit";
import imgCard1 from "../../assets/images/exp/shop.jpg";
import imgCard2 from "../../assets/images/exp/game.jpg";

const INITIAL_STATE = {
    items: [
        {
          title: "Mini React Shopfront",
          href: "https://demoshop.oxygen.digital/",
          src: imgCard1,
          text: `Simple Shopfront built using React / Redux.`,
          japanese: `Reactで構築されたオンラインショップ`
        },
        {
            title: "Retro Style Game",
            href: "https://game.oxygen.digital/",
            src: imgCard2,
            text: `Simple Retro style game built in vanilla Javascript. For Large screen only and requires a keyboard to play.`,
            japanese: `Javascriptで構築されたレトロゲーム`
          }
      ]
}

const expPortfolioSlice = createSlice({
    name: 'Experiment',
    initialState: INITIAL_STATE,
    reducers: {
        setExpPortolioItems(state, action){
            state.items = action.payload;
        }
    }
});

export const {setExpPortolioItems} = expPortfolioSlice.actions;
export const expPortfolioReducer = expPortfolioSlice.reducer;